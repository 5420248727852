<template>
  <v-app>
    <v-appbar :appBar="appBar" :search.sync="search" :leftSidebar.sync="leftSidebar" :rightSidebar.sync="rightSidebar"></v-appbar>
    <v-app-sidebar v-model="leftSidebar"> </v-app-sidebar>
    <v-change-log-sidebar v-model="rightSidebar"> </v-change-log-sidebar>
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view :appSearch="search"></router-view>
      </v-slide-y-transition>
    </v-main>
    <v-dialog v-model="dialog.show" :max-width="dialog.width">
      <v-card>
        <v-card-title class="text-overline">{{ dialog.title }}</v-card-title>
        <v-card-text style="white-space: pre-wrap">{{ dialog.text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :color="dialog.color" @click="$store.state.dialog.show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" :color="snackbar.backgroundColor" :timeout="snackbar.timeout">
      <div style="overflow-wrap: anywhere">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbar.color" text v-bind="attrs" @click="hideSnackbar"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import appSidebar from "@/AppSidebar";
import appBar from "@/AppBar";
import changeLogSidebar from "@/ChangeLogSidebar";
import authMixin from "@/mixins/AuthMixin.js";
import tabViewMixin from "@/mixins/TabViewMixin.js";
import appViewMixin from "@/mixins/AppViewMixin.js";
// import api from "api-client";
export default {
  name: "app",
  mixins: [authMixin, tabViewMixin, appViewMixin],
  data: () => ({
    studyId: null,
    projectId: null,
    leftSidebar: null,
    rightSidebar: null,
    mini: false,
    search: null
  }),
  components: {
    "v-appbar": appBar,
    "v-app-sidebar": appSidebar,
    "v-change-log-sidebar": changeLogSidebar
  },

  async created() {
    var t = this;
    t.setPageDefaults();
    var theme = localStorage.getItem("dark_theme");
    t.$vuetify.theme.dark = theme === "true" ? true : false;

    if (t.routeHasStudyId) {
      this.getStudies();
      t.studyId = this.$route.params.studyId;
    }

    if (t.routeHasProjectId) {
      this.getStudies();
      this.getProjects();
      t.projectId = this.$route.params.projectId;
    }
  },
  watch: {
    $route(to, from) {
      var t = this;

      if (to?.path !== from?.path) {
        t.setPageDefaults();
      }

      if (t.routeHasStudyId) {
        t.getStudies();
        t.studyId = this.$route.params.studyId;
      } else this.$store.commit("setStudy", null);

      if (t.routeHasProjectId) {
        t.getProjects();
        t.getStudies();
        t.projectId = this.$route.params.projectId;
      } else this.$store.commit("setProject", null);
    },

    user(val) {
      if (val) return Promise.all([this.getProjects(), this.getStudies()]);
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    routeHasStudyId() {
      return this.$route.params.studyId != null;
    },
    routeHasProjectId() {
      return this.$route.params.projectId != null;
    },
    studiesLoading() {
      return this.$store.state.studiesLoading;
    },
    projectsLoading() {
      return this.$store.state.projectsLoading;
    },
    dialog() {
      return this.$store.state.dialog;
    },
    snackbar() {
      return this.$store.state.snackbar;
    },
    hasStudy() {
      return this.$store.state.study != null;
    },
    hasProject() {
      return this.$store.state.project != null;
    },
    user() {
      return this.$store.state.user;
    },
    studies: {
      get() {
        return this.$store.state.studies;
      },
      set(val) {
        this.$store.commit("setStudies", val);
      }
    },
    projects: {
      get() {
        return this.$store.state.projects;
      },
      set(val) {
        this.$store.commit("setProjects", val);
      }
    }
  },
  methods: {
    setPageDefaults() {
      this.search = null;
      this.setThemePrimaryColor();
      this.initAppBar(); //resets to defaults
    },

    onStudyChange(val) {
      this.$router.push(`/study/${val}`);
    },
    onProjectChange(val) {
      this.$router.push(`/projects/${val}`);
    },
    hideSnackbar() {
      this.$store.commit("setSnackbar", { show: false });
    },
    getStudies() {
      return this.$store.dispatch("getStudies");
    },
    getProjects() {
      return this.$store.dispatch("getProjects");
    }
  }
};
</script>
<style>
.ipsos--text {
  color: #009d9c !important;
}
.v-select-fit-content.v-select.v-input--is-dirty input {
  display: none;
}

.v-select-fit-content .v-select__selection {
  max-width: none;
}
/* forces outlined with color set to keep border */
.v-card.theme--light.v-sheet--outlined {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.list-transition-move {
  transition: transform 300ms;
}
.list-transition-enter-active,
.list-transition-leave-active {
  transition: all 300ms;
}
.list-transition-enter,
.list-transition-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

/* .theme--light .v-card {
  background-color: #fafafa;
} */
.theme--light .v-main__wrap {
  background-color: #fafafa;
}
</style>
