class DateUtil {
  getTimeZoneOffset() {
    var off = new Date().getTimezoneOffset();
    var sign = off >= 0 ? "-" : "+"; //javascript has neg/pos reversed
    var hr = Math.floor(Math.abs(off / 60));
    var min = Math.abs(off % 60);
    var tzOff = `${sign}${hr}:${min}`;
    return tzOff;
  }
  browserTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  timeZones() {
    // var browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // return [{ value: browserTZ, text: browserTZ }];
    return [
      { value: "America/Detroit", text: "America/Detroit" },
      { value: "Asia/Kolkata", text: "Asia/Kolkata" },
      { value: "Asia/Shanghai", text: "Asia/Shanghai" }
    ];
  }

  hours(addHalfHours) {
    var hours = [];
    for (var i = 0; i < 24; i++) {
      var twentyfourHr = `${i.toString().padStart(2, "0")}`;
      var ampm = i / 12 < 1 ? "AM" : "PM";
      var twelveHr = i % 12;
      if (twelveHr === 0) {
        twelveHr = 12;
      }
      hours.push({ order: i, value: `${twentyfourHr}:00`, text: `${twentyfourHr}:00 (${twelveHr}:00 ${ampm})` });
      if (addHalfHours === true) hours.push({ order: i + 0.5, value: `${twentyfourHr}:30`, text: `${twentyfourHr}:30 (${twelveHr}:30 ${ampm})` });
    }
    return hours;
  }
}

exports.dateUtil = new DateUtil();
