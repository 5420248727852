<template>
  <v-btn
    v-bind="attrs"
    v-on="on"
    :disabled="typeof btn.disabled === 'function' ? btn.disabled() : btn.disabled"
    :loading="typeof btn.loading === 'function' ? btn.loading() : btn.loading"
    :to="btn.to ? btn.to : null"
    hide-details
    :text="btn.text == true"
    :icon="btn.icon === true"
    :color="btn.color || 'white'"
    :class="btn.class ? 'v-app-btn ' + btn.class : 'v-app-btn'"
    @click="btn.click ? btn.click() : null"
  >
    <v-icon v-if="btn.iconName">{{ btn.iconName }}</v-icon>
    {{ !btn.icon ? btn.name : "" }}
  </v-btn>
</template>

<script>
export default {
  name: "appButton",
  props: {
    on: Object,
    attrs: Object,
    btn: Object
    //  {
    //    tip: {
    //      text:"tooltip text",
    //      absolute: true,
    //      top: true,
    //      bottom: true,
    //      left: true,
    //      right: true
    //    },
    //   name: "Button",
    //    divider: false,
    //    id: null,
    //    items: [
    //      {
    //        name: "Item 1",
    //        divider: false,
    //        id: null,
    //       }
    //    ],
    //    color: null,
    //    text: false,
    //    icon: true,
    //    iconName: "mdi-plus",
    //    hidden: false,
    //    disabled: false,
    //    loading: false,
    //    class: null,
    //    to: null,
    //    click: null,
    //    badge: {
    //      overlap: false,
    //      text: "1",
    //      visible: true,
    //      bordered: false,
    //      bottom: true,
    //      color: "red",
    //      dot: false,
    //      offsetX: -10,
    //      offsetY: -10
    //    }
    //  }
  }
};
</script>
