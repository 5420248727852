import Vue from "vue";

function install(Vue) {
  Vue.mixin({
    methods: {
      $vt(key) {
        return this.$vuetify.lang.t(`$vuetify.${this.$options.name}.${key}`);
      }
    }
  });
}

Vue.use(install);
export default install;
