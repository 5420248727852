<template>
  <v-fab-transition>
    <v-app-bar-menu-btn v-if="btn.items" :btn="btn"></v-app-bar-menu-btn>
    <v-app-bar-badge-btn v-else-if="btn.badge" :btn="btn"></v-app-bar-badge-btn>
    <v-app-bar-tip-btn v-else-if="btn.tip" :btn="btn"></v-app-bar-tip-btn>
    <v-app-btn v-else :btn="btn"></v-app-btn>
  </v-fab-transition>
</template>

<script>
import appBtn from "./AppButton.vue";
import appBarTipBtn from "./AppBarTipButton.vue";
import appBarBadgeBtn from "./AppBarBadgeButton.vue";
import appBarMenuBtn from "./AppBarMenuButton.vue";
export default {
  name: "appBarButton",
  components: {
    "v-app-btn": appBtn,
    "v-app-bar-tip-btn": appBarTipBtn,
    "v-app-bar-badge-btn": appBarBadgeBtn,
    "v-app-bar-menu-btn": appBarMenuBtn
  },
  props: {
    btn: Object
  },
  data: () => ({})
};
</script>
