<template>
  <v-card outlined>
    <v-card-title class="text-overline"> Change Log </v-card-title>
    <v-card-subtitle class="mb-0 primary--text">
      <b>{{ path }}</b>
    </v-card-subtitle>
    <v-card-actions class="mt-0 pt-0">
      <v-spacer></v-spacer>
      <v-switch dense hide-details :input-value="recursive" label="Recursive" class="my-0 mr-2" v-on:change="onRecursiveChanged"></v-switch>
      <v-btn icon @click="getChangeLog()"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-card-actions>
    <v-skeleton-loader :loading="loading" type="list-item-avatar-two-line@3">
      <v-alert text v-if="loadingError" type="error">{{ loadingError }}</v-alert>
      <v-list v-else-if="entries && entries.length > 0" dense>
        <div>
          <template v-for="(entry, idx) in entries">
            <v-list-item :key="entry.id" @click="onClick(entry)">
              <v-list-item-action class="mr-2">
                <v-icon small :color="getIconColor(entry)">{{ getIcon(entry) }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ getTitle(entry) }} </v-list-item-title>
                <v-list-item-subtitle>{{ getSubtitle(entry) }}</v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text">{{ entry.userName || entry.userId }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ entry.dateUtc ? new Date(entry.dateUtc).toLocaleString() : null }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="idx < entries.length - 1" :key="`${entry.id}_divider`"></v-divider>
          </template>
          <div class="py-2 px-2">
            <v-btn v-show="canPageDown" :disabled="loading" class="mr-2" @click="pageDown">Load Previous {{ paging.take }}</v-btn>
            <v-btn v-show="canPageUp" @click="pageUp" :disabled="loading">Load Next {{ paging.take }}</v-btn>
          </div>
        </div>
      </v-list>
      <v-alert v-else text class="my-0"> There are no entries for this path. </v-alert>
    </v-skeleton-loader>
  </v-card>
</template>
<script>
import api from "api-client";
const MAX_PER_PAGE = 50;
export default {
  name: "changeLog",
  props: {
    studyId: String,
    projectId: String,
    path: String,
    recursive: Boolean
  },
  data: () => ({
    paging: {
      skip: 0,
      take: MAX_PER_PAGE
    },
    entries: [],
    replacePath: true,
    loading: false,
    loadingError: null
  }),
  mounted() {
    return this.getChangeLog();
  },
  watch: {
    recursive() {
      this.paging.skip = 0;
      this.paging.take = MAX_PER_PAGE;
      return this.getChangeLog();
    },
    path() {
      this.paging.skip = 0;
      this.paging.take = MAX_PER_PAGE;
      return this.getChangeLog();
    }
  },
  computed: {
    canPageDown() {
      return this.paging.skip > 0;
    },
    canPageUp() {
      return !this.loading && this.entryCount === this.paging.take;
    },
    entryCount() {
      return (!this.loading && this.entries?.length) || 0;
    }
  },
  methods: {
    onRecursiveChanged(val) {
      this.$store.commit("setChangelogRecursive", val);
    },
    onClick(entry) {
      if (!entry.diff) return;
      var d = JSON.parse(JSON.stringify(entry.diff));
      Object.keys(d).forEach(k => {
        delete d[k].type;
      });
      var txt = JSON.stringify(d, null, 4);
      this.$store.dispatch("showDialog", { title: entry.path, text: txt, timeout: null });
    },
    pageDown() {
      if (this.canPageDown) {
        this.paging.skip -= this.paging.take;
        if (this.paging.skip < 0) this.paging.skip = 0;
        return this.getChangeLog();
      } else return Promise.resolve();
    },
    pageUp() {
      if (this.canPageUp) {
        this.paging.skip += this.paging.take;
        return this.getChangeLog();
      } else return Promise.resolve();
    },

    getIconColor(entry) {
      switch (entry.action) {
        case "MODIFIED":
          return null;
        case "CREATED":
          return "success";
        case "DELETED":
          return "error";
      }
      return null;
    },
    getIcon(entry) {
      switch (entry.action) {
        case "MODIFIED":
          return "mdi-pencil";
        case "CREATED":
          return "mdi-plus";
        case "DELETED":
          return "mdi-minus";
      }
      return null;
    },
    getChangedColumns(entry) {
      return entry.changedColumns?.length > 0 ? entry.changedColumns.join(", ") : "";
    },
    getTitle(entry) {
      var path = this.getEntryPath(entry);
      if (path && path.length) return path;
      return this.getChanges(entry);
    },
    getSubtitle(entry) {
      var path = this.getEntryPath(entry);
      if (path && path.length) return this.getChanges(entry);

      return "";
    },
    getChanges(entry) {
      return `${entry.action} ${this.getChangedColumns(entry)}`;
    },
    getEntryPath(entry) {
      var path = this.replacePath ? entry.path?.replace(this.path, "") : entry.path;
      if (path[0] == "/") path = path.substring(1);
      return path;
    },

    getChangeLog() {
      var t = this;
      var dat = {
        studyId: t.studyId,
        projectId: t.projectId,
        path: t.path,
        paging: t.paging,
        recursive: t.recursive
      };
      t.loading = true;

      return api
        .getChangeLogEntries(dat)
        .then(r => {
          this.loadingError = null;
          t.entries = r || [];
        })
        .catch(() => {
          this.loadingError = "An error occcurred getting the change log";
        })
        .finally(() => {
          t.loading = false;
        });
    }
  }
};
</script>
