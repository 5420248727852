import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line no-unused-vars
import localizationHelper from "./plugins/localizationHelper";

Vue.config.productionTip = false;
var app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

router.onReady(() => {
  app.$mount("#app");
});
