<template>
  <v-app-bar
    class="app-bar"
    v-scroll="onScroll"
    :prominent="appBar.prominent"
    :shrink-on-scroll="appBar.shrinkOnScroll"
    :dark="appBar.dark"
    :color="appBar.color"
    :flat="appBar.flat"
    app
    clipped-right
    style="transition: background-color 300ms ease"
  >
    <v-app-bar-nav-icon @click="$emit('update:leftSidebar', !leftSidebar)"></v-app-bar-nav-icon>
    <v-slide-x-transition leave-absolute duration="300">
      <v-breadcrumbs v-if="breadcrumbs && breadcrumbs.length && !hideBreadcrumbs" :items="breadcrumbs" dense class="px-0 py-0" style="height: 48px">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item exact :to="item.href" :disabled="item.disabled">
            <span v-if="!item.disabled" class="underline-on-hover" :style="`color: white;font-weight:bold;`">{{ item.text }}</span>
            <span v-else :style="`color: white;${item.bold ? 'font-weight:bold;' : ''}`">{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-slide-x-transition>
    <div v-if="appBar.prominent" :style="titleStyle">
      <div>{{ appBar.title }}</div>
      <div v-if="appBar.description" class="text-subtitle-1 font-weight-light">
        <div :style="subtitleStyle" v-for="(itm, idx) in appBar.description.split('\n')" :key="idx">
          {{ itm }}
        </div>
      </div>
    </div>

    <v-spacer></v-spacer>

    <!-- search bar -->
    <v-fab-transition>
      <v-text-field
        v-if="appBar.search"
        :value="search"
        dense
        filled
        rounded
        clearable
        :color="appBar.dark ? 'white' : null"
        placeholder="Search"
        prepend-inner-icon="mdi-magnify"
        class="shrink expanding-search py-1"
        :class="{ closed: searchBoxClosed && !search }"
        @input="onSearch"
        @focus="searchBoxClosed = false"
        @blur="searchBoxClosed = true"
        hide-details
      ></v-text-field>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn v-if="historyIsVisible" icon @click="$emit('update:rightSidebar', !rightSidebar)" class="mx-0"><v-icon>mdi-history</v-icon></v-btn>
    </v-fab-transition>

    <v-app-bar-btn v-for="(btn, idx) in buttons" :btn="btn" :key="btn.id || idx"></v-app-bar-btn>

    <v-account v-if="appBar.showAccount" text></v-account>

    <template v-if="extended" v-slot:extension>
      <v-tabs v-model="navTab" :color="appBar.dark ? 'white' : null" align-with-title>
        <v-tab v-for="tab in appBar.extensionTabs" v-show="tab.show" :key="tab.id || tab.name" :disabled="tab.disabled" :href="tab.to" :to="tab.to">
          {{ tab.name }}
          <v-badge v-if="tab.badgeContent" offset-x="-3" :content="tab.badgeContent" :color="tab.badgeContentColor || 'primary'"></v-badge>
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>

      <template v-if="extensionButtons.length > 0">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-app-bar-btn v-for="(btn, idx) in extensionButtons" :btn="btn" :key="btn.id || idx"></v-app-bar-btn>
        </template>
        <v-app-bar-btn-grp v-else :btns="extensionButtons"></v-app-bar-btn-grp>
      </template>
    </template>
  </v-app-bar>
</template>

<script>
import account from "@/components/Account";
import authMixin from "@/mixins/AuthMixin.js";
import tabViewMixin from "@/mixins/TabViewMixin.js";
import appBarButton from "./AppBarButton.vue";
import appBarButtonGrp from "./AppBarButtonGroup.vue";
export default {
  name: "appBar",
  mixins: [authMixin, tabViewMixin],

  props: {
    appBar: Object,
    search: String,
    leftSidebar: Boolean,
    rightSidebar: Boolean
  },
  data: () => ({
    scrollY: 0,
    routeName: "",
    showSearch: true,
    searchBoxClosed: true
  }),
  components: {
    "v-account": account,
    "v-app-bar-btn": appBarButton,
    "v-app-bar-btn-grp": appBarButtonGrp
  },

  computed: {
    hideBreadcrumbs() {
      return !this.searchBoxClosed && this.$vuetify.breakpoint.xsOnly;
    },
    titleStyle() {
      return {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        marginTop: "50px",
        pointerEvents: "none",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: this.titleOpacity
      };
    },
    titleOpacity() {
      var over = 120;
      var pct = this.scrollY / over;
      var o = 1 - pct;
      return o < 0 ? 0 : o > 1 ? 1 : o;
    },
    subtitleStyle() {
      return {
        display: this.scrollY > 50 ? "none" : null,
        opacity: this.titleOpacity
      };
    },
    breadcrumbs() {
      var bc = this.appBar?.breadcrumbs || [];

      if (this.$vuetify.breakpoint.xsOnly && bc?.length > 1) return bc?.slice(-2);
      else if (this.$vuetify.breakpoint.mdAndDown && bc?.length > 2) return bc?.slice(-3);
      else return bc;
    },

    historyIsVisible() {
      return this.isAdmin && this.$store.getters.historyIsVisible;
    },
    moveExtensionButtons() {
      return false; // this.hasTabs && this.$vuetify.breakpoint.xsOnly;
    },

    extended() {
      return this.appBar.extended; //this.hasTabs || this.extensionButtons.length > 0;
    },
    hasTabs() {
      return this.appBar?.extensionTabs?.length > 0;
    },
    buttons() {
      return this.appBar?.buttons?.filter(btn => btn.hidden !== true) || [];
    },
    extensionButtons() {
      return this.moveExtensionButtons ? [] : this.appBar?.extensionButtons?.filter(btn => btn.hidden !== true) || [];
    }
  },
  methods: {
    onSearch(val) {
      this.$emit("update:search", val);
    },
    onScroll(e) {
      this.scrollY = e?.currentTarget?.scrollY || 0;
      //console.log("scroll", e.currentTarget.scrollY, e.currentTarget.pageYOffset, e.currentTarget.screenY);
    }
  }
};
</script>
<style>
.underline-on-hover:hover {
  text-decoration: underline;
}

.app-bar .v-breadcrumbs .v-breadcrumbs__divider {
  padding-left: 4px;
  padding-right: 4px;
}

.app-bar.v-app-bar.v-app-bar--shrink-on-scroll .v-toolbar__title .v-breadcrumbs li {
  font-size: inherit !important;
}

.v-input.expanding-search {
  transition: max-width 0.5s;
}

.v-input.expanding-search.closed {
  max-width: 70px !important;
}
.app-bar .v-toolbar__extension .v-tabs {
  margin-right: -16px;
}
.app-bar .v-slide-group__prev,
.app-bar .v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
/* .app-bar .v-slide-group__next,
.app-bar .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
} */
/* .app-bar .v-toolbar__extension {
  padding-right: 40px;
} */
/* .app-bar .v-item-group.v-slide-group.v-slide-group--is-overflowing {
  margin-right: 40px;
} */
</style>
