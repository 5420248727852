<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
    </template>
    <v-list dense>
      <template v-for="(btn, btnIdx) in btns">
        <template v-if="btn.items">
          <v-app-bar-menu-item v-for="(i, idx) in btn.items" :key="i.id || i.name || idx" :btn="i" :idx="btnIdx + idx"></v-app-bar-menu-item>
        </template>
        <v-app-bar-menu-item v-else :key="btn.id || btn.name || idx" :btn="btn" :idx="btnIdx"></v-app-bar-menu-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import appBarMenuItem from "./AppBarMenuItem.vue";
export default {
  name: "appBarButtonGroup",
  components: {
    "v-app-bar-menu-item": appBarMenuItem
  },
  props: {
    btns: Array
  },
  data: () => ({})
};
</script>
