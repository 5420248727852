export default {
  name: "tabViewMixin",

  data: () => ({}),

  computed: {
    navTab: {
      get() {
        // console.log(`get navTab from ${this.$route.hash}`);
        return this.$route.hash?.replace("#", "");
      },
      set(tabVal) {
        // console.log(`set navTab to ${tabVal}`);
        this.$router.replace({ hash: tabVal?.replace("#", "") });
      }
    }
  }
};
