<template>
  <v-badge
    v-if="btn.badge"
    :overlap="btn.badge.overlap"
    :content="btn.badge.text"
    :value="btn.badge.visible"
    :bordered="btn.badge.bordered"
    :bottom="btn.badge.bottom"
    :color="btn.badge.color"
    :dot="btn.badge.dot"
    :offset-x="btn.badge.offsetX"
    :offset-y="btn.badge.offsetY"
    class="v-app-badge-btn"
  >
    <!-- <template v-if="btn.badge.text" v-slot:badge>
            {{ btn.badge.text }}
          </template> -->
    <v-app-tip-btn :btn="btn"></v-app-tip-btn>
  </v-badge>
  <v-app-tip-btn v-else :btn="btn"></v-app-tip-btn>
</template>

<script>
import appTipBtn from "./AppBarTipButton.vue";

export default {
  name: "appBarBadgeButton",
  components: {
    "v-app-tip-btn": appTipBtn
  },
  props: {
    btn: Object
  }
};
</script>
