<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-app-btn :attrs="attrs" :on="on" :btn="btn"></v-app-btn>
    </template>
    <v-list dense>
      <v-app-bar-menu-item v-for="(i, idx) in btn.items" :btn="i" :key="i.id || i.name || idx" :idx="idx"></v-app-bar-menu-item>
    </v-list>
  </v-menu>
</template>

<script>
import appBtn from "./AppButton.vue";
import appBarMenuItem from "./AppBarMenuItem.vue";

export default {
  name: "appBarMenuButton",
  components: {
    "v-app-bar-menu-item": appBarMenuItem,
    "v-app-btn": appBtn
  },
  props: {
    btn: Object
  },
  data: () => ({})
};
</script>
