export default {
  appVersion: state => {
    return state.version;
  },
  isAuthenticated(state) {
    return state.user !== null && state.user !== undefined;
  },
  historyIsVisible(state) {
    return state.changeLogOptions?.path && state.changeLogOptions?.path?.length > 0;
  },
  defaultAppBar() {
    return JSON.parse(
      JSON.stringify({
        title: null,
        description: null,
        prominent: true,
        search: false,
        searchExpanded: false,
        shrinkOnScroll: true,
        color: "primary",
        dark: true,
        flat: false,
        buttons: [],
        breadcrumbs: [],
        extended: true,
        extensionTabs: [],
        extensionButtons: [],
        showAccount: true
      })
    );
  }
};
