import ActiveStateMixin from "./ActiveStateMixin";

export default {
  name: "appViewMixin",
  mixins: [ActiveStateMixin],
  data: () => ({
    routeName: null
  }),
  created() {
    this.routeName = this.$route.name;
    this.initAppBar();
  },
  watch: {
    isPageActive() {
      this.setThemePrimaryColor();
    },

    $route(to) {
      this.routeName = to.name;
    }
  },
  computed: {
    appBar: {
      get() {
        return this.$store.state.appBar;
      },
      set(v) {
        this.$store.commit("setAppBar", v);
      }
    },
    localizedRouteName() {
      return this.routeName ? this.$vuetify.lang.t(`$vuetify.router.${this.routeName}`) : "";
    },
    pageTitle: {
      get() {
        return this.appBar.title;
      },
      set(v) {
        this.appBar.title = v;
      }
    },
    pageDescription: {
      get() {
        return this.appBar.description;
      },
      set(v) {
        this.appBar.description = v;
      }
    }
  },
  methods: {
    getDefaultAppBar() {
      return {
        ...this.$store.getters.defaultAppBar,
        ...{
          title: this.localizedRouteName,
          breadcrumbs: [
            {
              bold: true,
              text: this.localizedRouteName,
              disabled: true
            }
          ]
        }
      };
    },

    initAppBar() {
      this.appBar = this.getDefaultAppBar();
    }
  }
};
