export default {
  version: `v${process.env.VUE_APP_VERSION || "0"}`,
  user: null,
  study: null,
  studies: [],
  project: null,
  projects: [],
  qfsRegion: null,
  studiesLoading: false,
  studiesLoadingError: null,
  projectsLoading: false,
  projectsLoadingError: null,
  sidebarItems: [],
  changeLogOptions: {
    path: null,
    recursive: false
  },
  appBar: {
    title: null,
    description: null,
    prominent: true,
    search: false,
    shrinkOnScroll: true,
    color: "primary",
    dark: true,
    flat: false,
    breadcrumbs: [],
    buttons: [],
    extended: true,
    extensionButtons: [],
    extensionTabs: [],
    showAccount: true
  },

  snackbar: {
    show: false,
    text: null,
    color: "primary",
    backgroundColor: null,
    timeout: null
  },
  dialog: {
    show: false,
    title: null,
    text: null,
    color: "primary"
  }
};
