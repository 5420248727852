<template>
  <v-navigation-drawer app temporary :value="value" v-on:input="onInput" color="tertiary" right width="400px">
    <v-change-log v-if="value" :path="path" :recursive="recursive"></v-change-log>
  </v-navigation-drawer>
</template>

<script>
import vChangeLog from "@/components/ChangeLog.vue";
import authMixin from "@/mixins/AuthMixin.js";
export default {
  name: "changeLogSidebar",
  mixins: [authMixin],
  props: {
    value: { type: Boolean }
  },
  components: {
    "v-change-log": vChangeLog
  },
  data: () => ({
    show: null
  }),
  mounted() {
    this.show = this.value;
  },
  computed: {
    recursive() {
      return this.$store.state.changeLogOptions.recursive;
    },
    path() {
      return this.$store.state.changeLogOptions.path;
    }
  },
  methods: {
    onInput(val) {
      this.$emit("input", val);
    }
  }
};
</script>
