<template>
  <v-container fluid class="px-0 pt-0"> </v-container>
</template>
<script>
export default {
  name: "homeView",
  components: {},
  data: () => ({}),
  mounted() {
    this.$store.commit("setSidebarItems", []);
    this.$store.commit("setStudy", null);
  }
};
</script>
