import getters from "./getters.js";

export default {
  setStudy(state, study) {
    state.study = study;
  },
  setChangelogPath(state, path) {
    state.changeLogOptions.path = path;
  },
  setChangelogRecursive(state, recursive) {
    state.changeLogOptions.recursive = recursive;
  },
  setStudiesLoading(state, loading) {
    state.studiesLoading = loading;
  },
  setQfsRegion(state, region) {
    state.qfsRegion = region;
  },
  setStudiesLoadingError(state, err) {
    state.studiesLoadingError = err;
  },
  setStudies(state, studies) {
    state.studies = studies || [];
  },
  setProject(state, project) {
    state.project = project;
  },
  setProjectsLoading(state, loading) {
    state.projectsLoading = loading;
  },
  setProjectsLoadingError(state, err) {
    state.projectsLoadingError = err;
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setUser(state, value) {
    state.user = value;
  },
  setDialog(state, value) {
    state.dialog = value;
  },
  setAppBar(state, bar) {
    state.appBar = bar ? bar : getters.defaultAppBar;
  },

  setSnackbar(state, value) {
    state.snackbar = value;
  },
  setSidebarItems(state, items) {
    state.sidebarItems = items;
  },

  setDarkTheme(state, value) {
    state.theme.dark = value;
  }
};
