<template>
  <v-tooltip v-if="btn.tip" :absolute="btn.tip.absolute" bottom class="v-app-tip-btn">
    <template v-slot:activator="{ on, attrs }">
      <v-app-btn :attrs="attrs" :on="on" :btn="btn"></v-app-btn>
    </template>
    <span>{{ btn.tip.text || btn.name }}</span>
  </v-tooltip>
  <v-app-btn v-else :btn="btn"></v-app-btn>
</template>

<script>
import appBtn from "./AppButton.vue";

export default {
  name: "appBarTipButton",
  components: {
    "v-app-btn": appBtn
  },
  props: {
    btn: Object
  },
  computed: {
    bottom() {
      return !this.btn.tip.top && !this.btn.tip.left && !this.btn.tip.right && !this.btn.tip.bottom ? true : this.btn.tip.bottom;
    }
  }
};
</script>
