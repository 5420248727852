import api from "api-client";
export default {
  getStudies(context) {
    if (context.state.studiesLoading) return Promise.resolve();
    context.commit("setStudiesLoading", true);
    return api
      .getStudies()
      .then(r => {
        context.commit("setStudiesLoadingError", null);
        context.commit("setQfsRegion", r.qfsRegion);
        context.commit("setStudies", r.studies);
      })
      .catch(() => {
        context.commit("setStudiesLoadingError", "There was a problem getting the studies");
      })
      .finally(() => {
        context.commit("setStudiesLoading", false);
      });
  },
  getProjects(context) {
    if (context.state.projectsLoading) return Promise.resolve();
    context.commit("setProjectsLoading", true);
    return api
      .getProjects()
      .then(r => {
        context.commit("setProjectsLoadingError", null);
        context.commit("setProjects", r);
      })
      .catch(() => {
        context.commit("setProjectsLoadingError", "There was a problem getting the projects");
      })
      .finally(() => {
        context.commit("setProjectsLoading", false);
      });
  },
  showDialog(context, opts) {
    var d = {
      title: opts.title || null,
      text: opts.text || null,
      color: opts.color || "primary",
      width: opts.width || null,
      show: true
    };
    context.commit("setDialog", d);
  },
  showSnackbar(context, opts) {
    var s = {
      text: opts.text || null,
      color: opts.color || "primary",
      backgroundColor: opts.backgroundColor || null,
      timeout: opts.timeout || null,
      show: true
    };
    context.commit("setSnackbar", s);
  },
  copyTextToClipboard({ dispatch }, txt) {
    navigator.clipboard.writeText(txt).then(
      function () {
        /* clipboard successfully set */
        dispatch("showSnackbar", {
          text: `${txt} copied to clipboard`,
          timeout: 3000
        });
      },
      function () {
        /* clipboard write failed */
        dispatch("showSnackbar", {
          text: `unable to copy to clipboard`,
          timeout: 3000
        });
      }
    );
  }
};
