<template>
  <span>
    <v-divider v-if="btn.divider && idx > 0"></v-divider>

    <v-list-item
      @click="btn.click ? btn.click() : null"
      :to="btn.to"
      v-if="typeof btn.hidden === 'function' ? !btn.hidden() : !btn.hidden"
      :disabled="typeof btn.disabled === 'function' ? btn.disabled() : btn.disabled == true"
    >
      <v-list-item-action v-if="btn.iconName">
        <v-icon>{{ btn.iconName }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ btn.name }} </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action v-if="btn.appendIconName">
        <v-icon>{{ btn.appendIconName }}</v-icon>
      </v-list-item-action>
    </v-list-item>
  </span>
</template>

<script>
export default {
  name: "appBarMenuItem",
  props: {
    btn: Object,
    idx: Number
  }
};
</script>
