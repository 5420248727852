<template>
  <v-navigation-drawer app :value="value" v-on:input="onInput" color="tertiary" :mini-variant="mini">
    <v-list dense flat>
      <v-list-item v-if="mini">
        <v-list-item-icon>
          <v-btn icon @click="mini = !mini" width="24px" height="s24px">
            <v-icon small>{{ navbarIcon }}</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content v-if="mini"></v-list-item-content>
      </v-list-item>

      <v-list-item-group color="primary">
        <v-list-item exact-path to="/studies">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.app.myStudies") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.prevent="toggleSidebar" width="24px" height="24px">
              <v-icon small>{{ navbarIcon }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <template v-if="$route.params.studyId && sidebarItems.length > 0">
          <v-divider></v-divider>
          <v-list-item exact-path v-for="s in sidebarItems" :key="s.id" :to="s.to">
            <v-list-item-icon v-if="mini">
              <v-icon>{{ s.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ s.text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="!mini">
              <v-icon>{{ s.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </template>

        <v-list-item exact-path to="/projects" v-if="isAdmin || isGsc">
          <v-list-item-icon>
            <v-icon>mdi-bookshelf</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.app.myProjects") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-shield-lock-outline</v-icon>
          </v-list-item-action>
        </v-list-item>

        <template v-if="$route.params.projectId && !$route.params.studyId && sidebarItems.length > 0">
          <v-divider></v-divider>
          <v-list-item exact-path v-for="s in sidebarItems" :key="s.id" :to="s.to">
            <v-list-item-icon v-if="mini">
              <v-icon>{{ s.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ s.text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="!mini">
              <v-icon>{{ s.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
        </template>

        <v-list-item v-if="canViewAssetCollections" to="/assets">
          <v-list-item-action>
            <v-icon>mdi-image-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Asset Collections</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="canViewImageQuestions" to="/image-questions">
          <v-list-item-action>
            <v-icon>mdi-file-question-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Image Questions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="isAuthenticated">
          <v-list-item v-if="isAdmin" to="/email/domains">
            <v-list-item-action>
              <v-icon>mdi-mailbox</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>All Domains</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <!-- <v-list-item v-if="isAdmin" to="/email-test">
            <v-list-item-action>
              <v-icon>mdi-send-clock-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Email Test</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-action>
          </v-list-item> -->
          <v-list-item v-if="isAdmin" to="/invitation-queue">
            <v-list-item-action>
              <v-icon>mdi-send-clock-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Invitation Queue</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="isAdmin" to="/surveys">
            <v-list-item-action>
              <v-icon>mdi-frequently-asked-questions</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Surveys</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="isAdmin" to="/project-templates">
            <v-list-item-action>
              <v-icon>mdi-frequently-asked-questions</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Project Templates</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="isAdmin" to="/study-templates">
            <v-list-item-action>
              <v-icon>mdi-frequently-asked-questions</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Study Templates</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-shield-lock-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item to="/curations">
            <v-list-item-action>
              <v-icon>mdi-book-multiple-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Curations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/feature-matrix">
            <v-list-item-action>
              <v-icon>mdi-book-multiple-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Feature Matrix</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import authMixin from "@/mixins/AuthMixin.js";

export default {
  components: {},
  name: "appSidebar",
  mixins: [authMixin],
  props: {
    value: { type: Boolean }
  },
  data: () => ({
    show: null,
    mini: false
  }),
  mounted() {
    this.show = this.value;
  },
  computed: {
    navbarIcon() {
      return this.mini ? "mdi-arrow-expand-right" : "mdi-arrow-expand-left";
    },
    sidebarItems() {
      return this.$store.state.sidebarItems || [];
    }
  },
  methods: {
    toggleSidebar() {
      this.mini = !this.mini;
    },
    onInput(val) {
      this.$emit("input", val);
    }
  }
};
</script>
