export default {
  name: "activeStateMixin",

  watch: {
    isPageActive() {
      this.setThemePrimaryColor();
    }
  },
  computed: {
    defaultPageColor() {
      return "#009d9c";
    },
    activePageColor() {
      return "#43A047";
    },
    isPageActive() {
      return false;
    }
  },
  methods: {
    setThemePrimaryColor() {
      var color = this.isPageActive === true ? this.activePageColor : this.defaultPageColor;
      this.$vuetify.theme.themes.light.primary = color;
      this.$vuetify.theme.themes.dark.primary = color;
    }
  }
};
