import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { logger } from "@/util/logger.js";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "280a2881-39da-4151-b2e7-cfabc29f42d9",
    redirectUri: window.location.origin, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: window.location.origin, // Must be registered as a SPA redirectURI on your app registration
    authority: "https://login.microsoftonline.com/1092197f-937b-439b-a403-93295587e186",
    validateAuthority: true
  },
  cache: {
    //cacheLocation: "localStorage"
    cacheLocation: "sessionStorage"
    //storeAuthStateInCookie: false,
    //forceRefresh: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (logger.shouldLog()) {
          switch (level) {
            case LogLevel.Error:
              logger.error(message);
              return;
            case LogLevel.Info:
              logger.info(message);
              return;
            case LogLevel.Verbose:
              logger.debug(message);
              return;
            case LogLevel.Warning:
              logger.warn(message);
              return;
            default:
              return;
          }
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
