import { loginRequest } from "../auth/authConfig";
import { InteractionType } from "@azure/msal-browser";
import auth from "../auth/msal-authprovider.js";
import { msalInstance } from "../auth/authConfig";
import { logger } from "@/util/logger.js";
import store from "@/store/index.js";
export function registerGuard(router) {
  router.beforeEach(async (to, from, next) => {
    logger.log("Guard: ", from.path, to.path);
    // console.log("Guard: ", from.path, to.path);
    store.commit("setChangelogPath", null);

    if (!to.meta.allowInsecure === true) {
      logger.log("Guard: requires auth");
      const request = {
        ...loginRequest,
        redirectStartPage: to.path === "/" ? "/studies" : to.fullPath
      };

      var r = await auth.signIn(InteractionType.Redirect, request);

      logger.log("Guard: authenticated", r.success);

      if (r.success) {
        next();
      } else {
        if (r.message === "Regional access denied.") next("/auth-region-denied");
        else next("/auth-failed");
      }
    } else {
      await msalInstance.handleRedirectPromise();
      next();
    }
  });
}
